import React from 'react';
import Layout from '../../layouts/Layout';
import {graphql, useStaticQuery} from 'gatsby';
import smallBanner from '../../images/small-banner.jpg';
import arrowRight from '../../images/arrow-right-w.svg';
import storageVault from '../../images/storage-vault-lofo.svg';

const FakeJobPage = () => {
    const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
    const menuItems = [
        {
            text: 'Home',
            href: '/',
            active: false
        }
    ];

    return (
        <Layout
            menuItems={menuItems}
            title={'Storage Vault Careers'}
            desc={'Storage Vault Careers'}
        >
            <section
                style={{
                    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 6e-05) 1.55%, rgba(0, 0, 0, 0.6) 100%), url('${smallBanner}')`
                }}
                className={
                    'flex min-h-[265px] w-full flex-row items-center bg-cover bg-top'
                }
            >
                <div
                    className={
                        'container mx-auto flex h-full flex-col justify-between px-5'
                    }
                >
                    <a
                        className={
                            'mb-6 mt-6     flex w-48 flex-row rounded-full border border-white px-6 py-2.5 text-white hover:text-white hover:opacity-90'
                        }
                        href={'/search'}
                    >
                        <img src={arrowRight} className={'mr-4 rotate-180'}/>
                        Back to Search
                    </a>
                    <div className={'flex flex-col'}>
                        <h1
                            className={
                                'font-sand pt-8 text-[38px] font-bold text-white'
                            }
                        >
                            Senior Project Marketing Manager
                        </h1>
                        <span
                            className={'mb-8 mt-3 font-sans text-xl text-white'}
                        >
							Scarborough, ON / Remote
						</span>
                    </div>
                </div>
            </section>
            <section>
                <div className={'container mx-auto my-12 flex flex-col-reverse lg:flex-row px-5'}>
                    <div className={'flex w-full lg:w-3/4 flex-col px-6'}>
                        <h2 className={'pb-4 font-sans text-4xl text-body'}>
                            About Us
                        </h2>
                        <hr className={'border-b-1 border-[#AAAAAA] py-4'}/>

                        <p className={'font-xl mb-4 font-sans text-body'}>
                            As the largest and fastest-growing storage company
                            in Canada, we are seeking a Senior Project Marketing
                            Manager to support our in-house Lead Generation
                            team. We are an entrepreneurial team with individual
                            and celebrated talents. We enjoy a fast pace,
                            collaborative environment and can meet on tight
                            timelines when required. We are a focused team,
                            working together to support each other and improve
                            performance. Our team members work effectively from
                            home with periodic in-person touchpoints.
                        </p>

                        <p className={'font-xl font-sans text-body'}>
                            As a Senior Marketing Project Manager, you will be
                            lead projects designed to create demand and improve
                            client expeirence. to improve performance, you will
                            work along side our Digital Team to create lead
                            generation campaigns, managing budgets to drive
                            demand through the use of paid, search, email and
                            social media. In addition, you will work closely
                            with UX/UI designers to optimize the client
                            experience and conversion. You are naturally
                            passionate about learning and self-improvement to
                            lead the industry.
                        </p>
                        <h2
                            className={
                                'mt-12 pb-4 font-sans text-4xl text-body'
                            }
                        >
                            Responsibilities
                        </h2>
                        <hr className={'border-b-1 border-[#AAAAAA] py-4'}/>
                        <p className={'font-xl font-sans text-body'}>
                            Your responsibilities will include:
                        </p>
                        <ul
                            className={'mt-4 pl-8'}
                            style={{listStyleType: 'disc'}}
                        >
                            <li className={'font-sans text-body'}>
                                Create and manage projects designed to improve
                                lead generation and client experience.
                            </li>
                            <li className={'font-sans text-body'}>
                                Support the Marketing and cross-functional teams
                                with research to identify the latest trends and
                                best practices that might influence design and
                                digital decisions for specific projects.
                            </li>
                            <li className={'font-sans text-body'}>
                                Support implementation of strategies across
                                Search Engine Marketing, Paid Social Marketing,
                                Social Media Marketing, Email Marketing and the
                                expansion to new channels to drive growth across
                                business units.
                            </li>
                            <li className={'font-sans text-body'}>
                                Support creation of user interface design,
                                architecture, and functionality within the
                                framework, according to usability best practices
                                and standards-compliant design
                            </li>
                            <li className={'font-sans text-body'}>
                                Assist the team in preparing other materials and
                                ensure that all assets meet brand guidelines
                            </li>
                            <li className={'font-sans text-body'}>
                                Participate in the planning and implementation
                                of design research
                            </li>
                            <li className={'font-sans text-body'}>
                                Stay up-to-date on developing trends and focus
                                on learning to lead the industry
                            </li>
                        </ul>
                        <h2
                            className={
                                'mt-12 pb-4 font-sans text-4xl text-body'
                            }
                        >
                            Your Experience
                        </h2>
                        <hr className={'border-b-1 border-[#AAAAAA] pt-4'}/>
                        <ul
                            className={'mt-2 pl-8'}
                            style={{listStyleType: 'disc'}}
                        >
                            <li className={'font-sans text-body'}>
                                7+ years in leadership and project management
                                with a experience in marketing
                            </li>
                            <li className={'font-sans text-body'}>
                                Proficient in performance marketing, conversion,
                                and customer acquisition with proven
                                demonstration across all digital channels to
                                drive leads, including SEM, SEO, Social, Email
                            </li>
                            <li className={'font-sans text-body'}>
                                Advance experience in Figma and Adobe Creative
                                Cloud
                            </li>
                            <li className={'font-sans text-body'}>
                                Positive, open-minded team player who brings
                                critical thinking, organization and analytical
                                skills
                            </li>
                            <li className={'font-sans text-body'}>
                                Must be detail-oriented, organized and have
                                professional experience collaborating in agile
                            </li>
                            <li className={'font-sans text-body'}>
                                Thrive in a fast pace, collaborative environment
                                and tight timelines
                            </li>
                        </ul>
                        <h2
                            className={
                                'mt-12 pb-4 font-sans text-4xl text-body'
                            }
                        >
                            Benefits
                        </h2>
                        <hr className={'border-b-1 border-[#AAAAAA] pt-4'}/>
                        <ul
                            className={'mt-2 pl-8'}
                            style={{listStyleType: 'disc'}}
                        >
                            <li className={'font-sans text-body'}>Dental Care</li>
                            <li className={'font-sans text-body'}>Extended health care</li>
                            <li className={'font-sans text-body'}>Life insurance</li>
                            <li className={'font-sans text-body'}>Store discount</li>
                        </ul>
                        <h2
                            className={
                                'mt-12 pb-4 font-sans text-4xl text-body'
                            }
                        >
                            Schedule
                        </h2>
                        <hr className={'border-b-1 border-[#AAAAAA] pt-4'}/>
                        <ul
                            className={'mt-2 pl-8'}
                            style={{listStyleType: 'disc'}}
                        >
                            <li className={'font-sans text-body'}>Monday to Friday</li>
                        </ul>
                        <h2
                            className={
                                'mt-12 pb-4 font-sans text-4xl text-body'
                            }
                        >
                            Supplemental pay types:
                        </h2>
                        <hr className={'border-b-1 border-[#AAAAAA] pt-4'}/>
                        <ul
                            className={'mt-2 pl-8'}
                            style={{listStyleType: 'disc'}}
                        >
                            <li className={'font-sans text-body'}>Bonus pay</li>
                        </ul>
                        <h2
                            className={
                                'mt-12 pb-4 font-sans text-4xl text-body'
                            }
                        >
                            Application question(s):
                        </h2>
                        <hr className={'border-b-1 border-[#AAAAAA] pt-4'}/>
                        <ul
                            className={'mt-2 pl-8'}
                            style={{listStyleType: 'disc'}}
                        >
                            <li className={'font-sans text-body'}>What are the top priorities of a Project Manager?</li>
                            <li className={'font-sans text-body'}>What is a good Google Ads Optimization score?</li>
                            <li className={'font-sans text-body'}>What makes a good UX?</li>
                        </ul>
                        <button
                            className={
                                'rounded-full bg-pri py-3 px-10 w-48 mt-6 font-sans text-lg text-white hover:opacity-90 focus:opacity-90'
                            }
                        >
                            Apply Now
                        </button>

                        <div className={'w-full bg-[#F0F4F8] p-5 mt-12'}>
                            <p className={'text-body font-sans text-lg mb-4'}>Equal Opportunity Employer</p>

                            <p className={'text-body font-sans text-lg'}>SotarageVault is an Equal Opportunity Employer and takes pride in maintaining a diverse
                                environment. We do not discriminate in recruitment, hiring, training, promotion or other
                                employment practices for reasons of race, color, religion, gender, national origin, age,
                                sexual orientation, marital or veteran status, disability, or any other legally
                                protected status.</p>

                        </div>
                    </div>
                    <div className={'flex w-full max-lg:mb-12 lg:w-1/4 flex-col'}>
                        <div className={'w-full bg-[#F0F4F8] p-5'}>
                            <img className={'mb-6'} src={storageVault} />
                            <span className={'mb-10 font-sans text-body text-xl'}>Senior Project Marketing Manager</span>
                            <p className={'font-sans mt-3'}>Scarborough, ON / Remote</p>
                            <p className={'font-sans mt-3'}>$85,000–$105,000 a year</p>
                            <p className={'font-sans mt-3'}>Fulltime</p>
                            <p className={'font-sans mt-3'}>Work from home with in-person touchpoints</p>
                            <button
                                className={
                                    'mx-auto text-center flex my-6 mt-10 rounded-full bg-pri py-3 px-10 w-48 mt-6 font-sans text-lg text-white hover:opacity-90 focus:opacity-90'
                                }
                            >
                                Apply Now
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default FakeJobPage;
